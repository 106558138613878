<template>
  <div
    v-if="shouldDisplayTriptypeFilters"
    class="flex justify-between sm:justify-start items-center flex-row"
  >
    <button
      class="filter-bar__type__btn btn rounded-[1.25rem] py-1 sm:py-2 px-4 font-normal normal-case flex flex-col sm:flex-row items-center mb-1"
      :class="getFilterButtonClass('all')"
      data-filter="all-trip-types"
      @click="setAll"
    >
      <span class="nowrap sm:pl-1">
        <fa-layers>
          <fa
            icon="plane"
            transform="shrink-6 up-4 right-5"
          />
          <fa
            icon="bus"
            transform="shrink-6 down-4 left-5"
            opacity
          />
        </fa-layers>
      </span>
      <screenWidthProvider v-slot="{ isWider }">
        <span
          v-if="(isWider('sm'))"
          class="block sm:inline-block ml-0 sm:ml-2 pl-1"
          data-i18n="calendar.allTypes"
        >{{ $t('calendar.allTypes') }}</span>
        <span
          v-else
          class="block sm:inline-block ml-0 sm:ml-2 pl-1"
          data-i18n="calendar.mobileAllTypes"
        >{{ $t('calendar.mobileAllTypes') }}</span>
      </screenWidthProvider>
    </button>
    <button
      class="filter-bar__type__btn btn rounded-[1.25rem] py-1 sm:py-2 px-4 font-normal normal-case flex flex-col sm:flex-row items-center mb-1"
      :class="getFilterButtonClass('bus')"
      data-filter="only-bus-trips"
      @click="setBus"
    >
      <fa
        class="sm:inline-block"
        icon="bus"
      />
      <span
        class="block sm:inline-block ml-0 sm:ml-2"
        data-i18n="calendar.onlyBus"
      >{{ $t('calendar.onlyBus') }}</span>
    </button>
    <button
      v-if="isOnlyFlightButton"
      class="btn rounded-[1.25rem] py-1 sm:py-2 px-4 font-normal normal-case flex items-center flex-col sm:flex-row mb-1"
      :class="getFilterButtonClass('flight')"
      @click="setFilterFlight()"
    >
      <fa
        class="inline-block"
        icon="plane"
      />
      <span
        class="block sm:inline-block ml-0 sm:ml-2"
        data-i18n="calendar.onlyFlights"
      >{{ $t('calendar.onlyFlights') }}</span>
    </button>
    <Dropdown
      v-else
      ref="airportSelect"
      class="filter-bar__type__btn mb-1"
      data-filter="only-flight-trips"
      close-on-click
    >
      <template #trigger="{ toggle }">
        <div class="flex size-full">
          <button
            class="btn grow rounded-[1.25rem] py-1 sm:py-2 px-4 font-normal normal-case flex items-center flex-col sm:flex-row"
            :class="getFilterButtonClass('flight')"
            type="button"
            @click="toggle"
          >
            <fa
              class="inline-block"
              icon="plane"
            />
            <span
              class="block sm:inline-block ml-0 sm:ml-2"
              data-i18n="calendar.onlyFlights"
            >{{ displayFilterFlight }}</span>
          </button>
        </div>
      </template>
      <template #content>
        <div class="w-full bg-white">
          <button
            v-for="(option, index) in airportList"
            :key="index"
            class="dropdown-item"
            type="button"
            @click="setFilterFlight(option.iata)"
          >
            {{ option.name }}
          </button>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import events from '@layers/web/constants/events.js'
import { TRIP_TYPES } from '@packages/trip/constants/types.ts'

export default defineNuxtComponent({
  props: {
    airports: {
      type: Array,
      default: () => [],
    },

    darkFilter: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },

    isOnlyFlightButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['filter', 'update', 'airport', events.selectedAirport],

  data () {
    return {
      TRIP_TYPES,
    }
  },

  computed: {
    ...mapState(useCalendarStore, {
      filterTripType: 'filterTripType',

      filters: 'filters',
      filterDuration: 'filterDuration',
      filterLastMinute: 'filterLastMinute',
      selectedStartDateStore: 'selectedStartDate',
    }),

    ...mapState(useLocaleStore, ['locale']),

    airportList () {
      return (this.airports || [])
        .map(x => ({
          ...x,
          iata: x?.iata || x?.code || x?.value,
          name: x?.name,
        }))
        .filter(x => x?.iata)
    },

    displayFilterFlight () {
      if (
        this.filterTripType === TRIP_TYPES.flight &&
        this.filters?.airport &&
        this.filters.airport !== TRIP_TYPES.all
      ) {
        const ap = this.airportList.find(o => o.iata === this.filters.airport)
        return ap?.name || this.$t('calendar.onlyFlights')
      }

      return this.$t('calendar.onlyFlights')
    },

    shouldDisplayTriptypeFilters () {
      if (this.locale !== 'no') {
        return true
      }

      return false
    },
  },

  watch: {
    filterTripType (v) {
      if (v === TRIP_TYPES.flight) {
        this.setFilter({ filter: 'onlyflight', value: 1 })
        this.setFilter({ filter: 'onlybus', value: 0 })
        return
      }

      this.setFilter({ filter: 'airport', value: TRIP_TYPES.all })

      if (v === TRIP_TYPES.bus) {
        this.setFilter({ filter: 'onlyflight', value: 0 })
        this.setFilter({ filter: 'onlybus', value: 1 })
      } else {
        this.setFilter({ filter: 'onlyflight', value: 0 })
        this.setFilter({ filter: 'onlybus', value: 0 })
      }

      this.$emit('filter')
    },
  },

  methods: {
    ...mapActions(useCalendarStore, [
      'setFilter',
      'setTripType',
    ]),

    updateType (type, iata = null) {
      const airport = !iata || iata === TRIP_TYPES.all ? null : iata

      this.setTripType(type)
      this.setFilter({ filter: 'onlyflight', value: type === TRIP_TYPES.flight ? 1 : 0 })
      this.setFilter({ filter: 'onlybus', value: type === TRIP_TYPES.bus ? 1 : 0 })
      this.setFilter({ filter: 'airport', value: iata || 'all' })

      this.$emit('update', { type, airport })
      this.setAirport(airport)
    },

    setFilterFlight (iata) {
      this.updateType(TRIP_TYPES.flight, !iata || iata === TRIP_TYPES.all ? null : iata)
    },

    setAll () {
      this.updateType(TRIP_TYPES.all)
    },

    setBus () {
      this.updateType(TRIP_TYPES.bus)
    },

    setAirport (value) {
      this.$event.$emit(events.selectedAirport, value)
      this.$emit('airport', value)
      this.$emit('filter')
    },

    getFilterButtonClass (filterName) {
      const classes = []

      if (this.filterTripType === TRIP_TYPES[filterName]) {
        if (this.darkFilter) {
          classes.push('bg-black text-white')
        } else {
          classes.push('bg-medium-blue text-white')
        }
      } else if (this.darkFilter) {
        classes.push('bg-black-blue-transparent text-white')
      } else {
        classes.push('bg-white-blue text-black')
      }

      if (!this.vertical) {
        classes.push('mr-1 md:mr-3')
      }

      return classes.join(' ')
    },
  },
})
</script>

<style lang="scss">
.filter-bar__type__btn {
  min-width: 120px;

  @media all and (max-width:theme('screens.sm')) {
    min-width: 0;
    width: calc(1 / 3 * 100%);
  }
}
</style>
